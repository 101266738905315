import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkforce } from "../../Store/ApiActions/workforceApi";
import "./UserHistory.css";

const UserHistory = () => {
  const dispatch = useDispatch();
  const allWorkforceData = useSelector(
    (state) => state.workforce?.getworkforceData
  );

  const [userData, setUserData] = useState(null);
  console.log(userData);

  useEffect(() => {
    dispatch(getWorkforce());
  }, [dispatch]);

  const getUserData = () => {
    const userEmail = localStorage.getItem("userEmail");
    const loginUser = allWorkforceData.find((item) => userEmail === item.email);
    setUserData(loginUser);
  };

  useEffect(() => {
    getUserData();
  }, [allWorkforceData]);

  return (
    <>
      <div className="status-container mt-3">
        <h2>Your Status</h2>
      </div>
      <div className="w-100 d-flex justify-content-center mt-3">
        {userData?.activeStatus === 1 ? (
          <div className=" status active text-center">
            Your Status is Active
          </div>
        ) : userData?.activeStatus === 2 ? (
          <div className="Status rejected text-center">
            Your Status is Rejected
          </div>
        ) : (
          <div className="Status pending text-center">
            {" "}
            Your Status is Pending
          </div>
        )}
      </div>
    </>
  );
};

export default UserHistory;
