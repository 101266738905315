import { configureStore } from "@reduxjs/toolkit";
import LoginReducer from "./Reducers/LoginReducer";
import AdminReducer from "./Reducers/AdminReducer";
import workforceReducer from "./Reducers/workforceReducer";
import signupReducer from "./Reducers/signupReducer";
import userDataReducer from "./Reducers/userDataReducer";

export const store = configureStore({
  reducer: {
    login: LoginReducer,
    admin: AdminReducer,
    workforce: workforceReducer,
    signup: signupReducer,
    user: userDataReducer
  },
});
