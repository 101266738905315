import React from "react";
import Cards from "../../Components/Cards/Cards";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ContactsIcon from "@mui/icons-material/Contacts";

const Home = ({isAdmin}) => {
  const cardsInfo = [
    {
      title: "Admin",
      icon: <AdminPanelSettingsIcon />,
      url: "/admin",
      access: ["SuperAdmin"],
    },
    {
      title: "Work Force",
      icon: <Diversity3Icon />,
      url: "/workforce",
      access: ["Admin", "SuperAdmin"],
    },
    {
      title: "Forms",
      icon: <ContactsIcon />,
      url: "/form",
      access: ["Employee","Admin", "SuperAdmin"],
    },
  ];
  return (
    <div className="home-cards w-100 py-2 text-center">
      <div className="d-flex flex-wrap flex-grow-2 justify-content-around mt-4">
        
        {cardsInfo.map((item) => (
          <Cards title={item.title} icon={item.icon} url={item.url} access={item.access} isAdmin={isAdmin}/>
        ))}
      </div>
    </div>
  );
};

export default Home;
