import React, { useEffect, useState } from "react";
import classes from "./Workforce.module.css";
import { Col, Divider, Row } from "antd";
import SignatureCanvas from "react-signature-canvas";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import page2P1 from "../../Asset/Images/W4FormPage2.png";
import page3P1 from "../../Asset/Images/W4FormPage3.1.png";
import page3P2 from "../../Asset/Images/W4FormPage3.2.png";
import page3P3 from "../../Asset/Images/W4FormPage3.3.png";
import page3P4 from "../../Asset/Images/W4FormPage3.4.png";
import page3P5 from "../../Asset/Images/W4FormPage3.5.png";
import page4 from "../../Asset/Images/W4FormPage4.png";
import W9Form from "./W9Form";
import axios from "axios";
import Buttons from "../Button/Buttons";
import { toast } from "react-toastify";
import { userUpdataData } from "../../Store/ApiActions/userDataApi";
import { useDispatch } from "react-redux";
import { CheckCircleFilled } from "@ant-design/icons";

const inputFields = [
  {
    name: "fName",
    label: "First Name",
  },
  {
    name: "lName",
    label: "Last Name",
  },
  {
    name: "filingStatus",
    label: "Filing Status",
    isSelect: true,
    options: [
      { id: 1, option: "Single" },
      { id: 2, option: "Married filing jointly" },
      // Add other filing status options as needed
    ],
  },
  {
    name: "allowances",
    label: "Allowances",
    type: "number",
  },
  {
    name: "additionalWithholding",
    label: "Additional Withholding",
    type: "number",
  },
];
const mainHeadingCenter = [
  "Complete Form W-4 so that your employer can withhold the correct federal income tax from your pay.",
  "Give Form W-4 to your employer.",
  "Your withholding is subject to review by the IRS.",
];
const checkBox = [
  {
    name: "single",
    text: "Single or Married filing separately",
  },
  {
    name: "marriedJoint",
    text: "Married filing jointly or Qualifying surviving spouse",
  },
  {
    name: "headOfHousehold",
    text: "Head of household (Check only if you're unmarried and pay more than half the costs of keeping up a home for yourself and a qualifying individual.)",
  },
];
const page3Fields = [
  {
    name: "twoJob",
    text: "1",
  },
  {
    name: "threeJobA",
    text: "2a",
  },
  {
    name: "threeJobaB",
    text: "2b",
  },
  {
    name: "threeJobaC",
    text: "2c",
  },
  {
    name: "payPerioid",
    text: "3",
  },
  {
    name: "divide",
    text: "4",
  },
];
const page3P2Fields = [
  {
    name: "one",
    text: "1",
  },
  {
    name: "two",
    text: "2",
  },
  {
    name: "three",
    text: "3",
  },
  {
    name: "four",
    text: "4",
  },
  {
    name: "five",
    text: "5",
  },
];
const canvasProps = {
  className: `${classes.signature} ${
    window.innerWidth < 500 ? "xs" : window.innerWidth < 850 ? "sm" : "lg"
  }`,
  width: window.innerWidth < 500 ? 160 : window.innerWidth < 850 ? 300 : 400,
  height: window.innerWidth < 500 ? 40 : window.innerWidth < 850 ? 60 : 70,
};

const W4Form = ({
  path,
  basicFormData,
  setSelectedTab,
  allBasicformData,
  isAdmin,
  allWorkforceData,
}) => {
  console.log("  allWorkforceData from w4 form==>  ", allWorkforceData);
  const [name, setName] = useState("");
  const [signatureRef, setSignatureRef] = useState(null);
  const [formData, setFormData] = useState({});
  const [CheckBox, setCheckBox] = useState(null);
  const dispatch = useDispatch();
  console.log("W4Form Data", formData);

  // Get LoggedIn Users  Data

  const [userData, setUserData] = useState(null);
  console.log(userData);

  const getUserData = () => {
    const userEmail = localStorage.getItem("userEmail");

    const loginUser = allWorkforceData?.find(
      (item) => userEmail === item.email
    );
    setUserData(loginUser);
  };

  useEffect(() => {
    getUserData();
  }, [allWorkforceData]);

  // Check if there is saved form data when the component mounts
  useEffect(() => {
    const savedData = localStorage.getItem("W4_Form");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData(parsedData);
    }
  }, []);

  const isDataInLS = formData && Object.keys(formData).length !== 0;
  console.log("isDataInLS>>>", isDataInLS);

  const handleClearSignature = () => {
    signatureRef.clear();
  };

  const handleDownloadAndUpload = async () => {
    const input = document.getElementById("form");

    try {
      const canvas = await html2canvas(input, {
        scale: 2,
      });

      const imgData = canvas.toDataURL("image/png", 1.0);

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [canvas.width / 2.8, canvas.height / 2.8],
      });

      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        canvas.width / 2.8,
        canvas.height / 2.8
      );
      pdf.save(`${"W4form"}.pdf`);
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed
    }
  };

  const handleChange = (e, CheckBoxOption) => {
    const { name, value } = e.target;

    // If CheckBoxOption is not null, set CheckBoxOption as the only selected option
    if (CheckBoxOption) {
      setFormData({
        ...formData,
        [name]: "on",
      });

      setCheckBox(CheckBoxOption);
      const checking = checkBox.filter((item) => item.name !== CheckBoxOption);

      checking.forEach((item) => {
        console.log(item.name);
        setFormData((prevData) => ({
          ...prevData,
          [item.name]: "off",
        }));
      });
    }
    // if (name === CheckBoxOption) {
    // setFormData({
    //   ...formData,
    //   [name]: "on",
    // });
    // }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleNext = async () => {
    const input = document.getElementById("form");
    const email = localStorage.getItem("userEmail");
    localStorage.setItem("W4_Form", JSON.stringify(formData));

    try {
      const canvas = await html2canvas(input, { scale: 2 });

      // Adjusting the JPEG quality to reduce file size
      const imgData = canvas.toDataURL("image/jpeg", 0.7);

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [canvas.width / 2.8, canvas.height / 2.8],
      });

      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        canvas.width / 2.8,
        canvas.height / 2.8
      );

      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        canvas.width / 2.8,
        canvas.height / 2.8
      );

      const pdfBlob = pdf.output("blob");

      const form = new FormData();
      Object.entries(allBasicformData.allData).forEach(([name, value]) => {
        if (name !== "profile")
          form.append(name, value === undefined ? "" : value);
      });

      form.append("profile", allBasicformData.profile);
      form.append("formStatus", 1);
      form.append("pdf", pdfBlob, "w4Form.pdf");
      form.append("email", email);

      const response = await dispatch(userUpdataData(form));

      if (response.payload.status) {
        setTimeout(() => toast.success("Added Successfully"), 2000);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed
    }
  };

  return (
    <div>
      <div className={classes.main} id="form">
        {path === "/employee" ? (
          <div>
            {/* W4Fom */}
            {/* Page1 */}
            <div className={classes.page}>
              <Row gutter={8} justify={"space-between"}>
                <Col span={4} className={classes.colBorderRight}>
                  Form{" "}
                  <span className={`fw-bolder`} style={{ fontSize: "4vmin" }}>
                    W-4
                  </span>{" "}
                  <br />
                  <br />
                  Department of the Treasury Internal Revenue Service
                </Col>

                <Col span={15} className={classes.colDiv}>
                  <span
                    className="d-flex justify-content-center fw-bolder mt-3"
                    style={{ fontSize: "2.2vmin" }}
                  >
                    Employee's Withholding Certificate
                  </span>
                  {mainHeadingCenter.map((text, index) => (
                    <span
                      key={index}
                      className="d-flex justify-content-center fw-bold pt-1"
                    >
                      {text}
                    </span>
                  ))}
                </Col>

                <Col span={4} className={classes.colBorderLeft}>
                  <div className="mt-3 mx-2 mb-1">OMB No. 1545-0074</div>
                  <Divider className={classes.customDivider} />
                  <div className={classes.twentythree}>2023</div>
                </Col>
              </Row>
              <Divider className={classes.customDivider} />
              <Row gutter={8} justify={"space-between"}>
                <Col span={3} className={classes.colBorderRight}>
                  <div
                    className={`pt-2 fw-bold`}
                    style={{ fontSize: "1.5vmin" }}
                  >
                    Step 1: <br /> Enter Personal <br /> Information
                  </div>
                </Col>
                <Col span={21}>
                  <Row>
                    <Col span={18} className={classes.colDiv}>
                      <Row>
                        <Col span={12} className={classes.colBorderRight}>
                          <div className="px-2">
                            (a) First name and middle initial{" "}
                            <div className="mb-1">
                              <input
                                className={classes.text}
                                type="text"
                                name="fName"
                                value={basicFormData.fName}
                                readOnly
                                onChange={(e) => {
                                  setName(e.target.value);
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col span={12} className={`${classes.colDiv}`}>
                          <div className="px-2">
                            Last name{" "}
                            <div>
                              <input
                                className={classes.text}
                                type="text"
                                name="lName"
                                value={basicFormData.lName}
                                readOnly
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Divider className={classes.customDivider} />
                      <div className="mx-2">
                        Address
                        <div className="">
                          <input
                            className={`w-100 mb-1 ${classes.text}`}
                            type="text"
                            name="address"
                            value={basicFormData.address}
                            readOnly
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <Divider className={classes.customDivider} />
                      <div className="mx-2">
                        City or town, state, and ZIP code
                        <div className="">
                          <input
                            className={`w-100 mb-1 ${classes.text}`}
                            type="text"
                            name="city"
                            value={isDataInLS ? formData.city : ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col span={6} className={classes.colBorderLeft}>
                      <div className="px-1 fw-bold">
                        (b) Social security number
                      </div>
                      <div>
                        {" "}
                        <input
                          className={`w-80 mb-1 mx-1 ${classes.number}`}
                          type="number"
                          name="Security"
                          value={basicFormData.ssn}
                          readOnly
                          onChange={handleChange}
                        />
                      </div>
                      <Divider className={classes.customDivider} />
                      <div className="px-1 fw-bold">
                        Does your name match the name on your social security
                        card?{" "}
                        <span className="fw-normal">
                          If not, to ensure you get credit for your earnings,
                          contact SSA at 800-772-1213 or go to www.ssa.gov.
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Col span={24} className={classes.colDiv}>
                    <Divider className={classes.customDivider} />
                    <span className="mx-1 me-2 fw-bold">(c)</span>
                    {checkBox.map((item) => (
                      <span className={item.name === "single" ? "" : "mx-3"}>
                        <input
                          key={item.name}
                          className={classes.checkBox}
                          type="checkbox"
                          name={item.name}
                          checked={isDataInLS && formData[item.name] === "on"}
                          // checked={CheckBox === item.name}
                          onChange={(e) => {
                            handleChange(e, item.name);
                          }}
                        />
                        <span key={item.name} className="fw-bold">
                          {item.text}
                        </span>
                        <br />
                      </span>
                    ))}
                  </Col>
                </Col>
              </Row>

              <Divider className={classes.customDivider} />
              <Row className="my-2">
                <div style={{ fontSize: "2vmin" }}>
                  <span className="fw-bold">
                    Complete Steps 2-4 ONLY if they apply to you; otherwise,
                    skip to Step 5.
                  </span>
                  <span>
                    {" "}
                    See page 2 for more information on each step, who can claim
                    exemption from withholding, other details, and privacy.
                  </span>
                </div>
              </Row>
              <Divider className={classes.customDivider} />
              <Row>
                <Col span={4}>
                  <div className={`pt-2 fw-bold`} style={{ fontSize: "2vmin" }}>
                    Step 2: <br /> Multiple Jobs <br /> or Spouse Works{" "}
                  </div>
                </Col>
                <Col span={20} className={`mt-2`} style={{ fontSize: "2vmin" }}>
                  Complete this step if you (1) hold more than one job at a
                  time, or (2) are married filing jointly and your spouse also
                  works. The correct amount of withholding depends on income
                  earned from all of these jobs. <br /> Do{" "}
                  <span className="fw-bold">only one </span> of the following.{" "}
                  <br />
                  <span className="fw-bold">(a) </span> Reserved for future use.{" "}
                  <br /> <span className="fw-bold">(b) </span> Use the Multiple
                  Jobs Worksheet on page 3 and enter the result in Step 4(c)
                  below; or <br /> <span className="fw-bold">(c) </span> If
                  there are only two jobs total, you may check this box. Do the
                  same on Form W-4 for the other job. This option is generally
                  more accurate than (b) if pay at the lower paying job is more
                  than half of the pay at the higher paying job. Otherwise, (b)
                  is more accurate ..................{" "}
                  <input
                    className={classes.checkBox}
                    type="checkbox"
                    name="accuratePayCheckBox"
                    checked={
                      isDataInLS && formData.accuratePayCheckBox === "on"
                    }
                    onChange={handleChange}
                  />{" "}
                  <br /> <span className="fw-bold">TIP: </span> If you have
                  self-employment income, see page 2.
                </Col>
              </Row>
              <Row className="my-2">
                <div style={{ fontSize: "2vmin" }}>
                  <span className="fw-bold">
                    Complete Steps 3-4(b) on Form W-4 for only ONE of these
                    jobs.
                  </span>
                  <span>
                    {" "}
                    Leave those steps blank for the other jobs. (Your
                    withholding will be most accurate if you complete Steps
                    3-4(b) on the Form W-4 for the highest paying job.)
                  </span>
                </div>
              </Row>
              <Divider className={classes.customDivider} />
              <Row>
                <Col span={4}>
                  <div className={`pt-2 fw-bold`} style={{ fontSize: "2vmin" }}>
                    Step 3: <br /> Claim Dependent <br /> and Other Credits
                  </div>
                </Col>
                <Col span={15} className={`mt-2`} style={{ fontSize: "2vmin" }}>
                  If your total income will be $200,000 or less ($400,000 or
                  less if married filing jointly): <br /> Multiply the number of
                  qualifying children under age 17 by $2,000 ${" "}
                  <input
                    className={classes.number}
                    type="number"
                    name="childUnder17"
                    value={isDataInLS ? formData.childUnder17 : ""}
                    onChange={handleChange}
                  />{" "}
                  <br />
                  Multiply the number of other dependents by $500.......... ${" "}
                  <input
                    className={classes.number}
                    type="number"
                    name="otherDependent"
                    value={isDataInLS ? formData.otherDependent : ""}
                    onChange={handleChange}
                  />{" "}
                  <br />
                  Add the amounts above for qualifying children and other
                  dependents. You may add to this the amount of any other
                  credits. Enter the total here ..................{" "}
                </Col>
                <Col span={1} className={classes.colAllignBottom}>
                  3
                </Col>
                <Col span={4} className={classes.colAllignBottom}>
                  ${" "}
                  <input
                    className={classes.number}
                    type="number"
                    name="ChildAndDependentTotal"
                    value={isDataInLS ? formData.ChildAndDependentTotal : ""}
                  />{" "}
                </Col>
              </Row>
              <Divider className={classes.customDivider} />
              <Row className="mb-4">
                <Col span={4}>
                  <div className={`pt-2 fw-bold`} style={{ fontSize: "2vmin" }}>
                    Step 4: <br /> (Optional): <br /> Other Adjustments
                  </div>
                </Col>
                <Col span={20} className={`mt-2`}>
                  <Row>
                    <Col
                      span={18}
                      style={{ fontSize: "2vmin", marginBottom: 2 }}
                    >
                      <div className="mb-2">
                        <span className="fw-bold">
                          (a) Other income (not from jobs).
                        </span>{" "}
                        If you want tax withheld for other income you expect
                        this year that won't have withholding, enter the amount
                        of other income here. This may include interest,
                        dividends, and retirement income .........
                      </div>
                    </Col>
                    <Col span={2} className={`mx-0 ${classes.colAllignBottom}`}>
                      4(a)
                    </Col>
                    <Col span={4} className={classes.colAllignBottom}>
                      ${" "}
                      <input
                        className={classes.number}
                        type="number"
                        name="otherIncome"
                        onChange={handleChange}
                        value={isDataInLS ? formData.otherIncome : ""}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      span={18}
                      style={{ fontSize: "2vmin", marginBottom: 2 }}
                    >
                      <div className="mb-2">
                        <span className="fw-bold">(b) Deductions. </span> If you
                        expect to claim deductions other than the standard
                        deduction and want to reduce your withholding, use the
                        Deductions Worksheet on page 3 and enter the result
                        here..........
                      </div>
                    </Col>
                    <Col span={2} className={`mx-0 ${classes.colAllignBottom}`}>
                      4(b)
                    </Col>
                    <Col span={4} className={classes.colAllignBottom}>
                      ${" "}
                      <input
                        className={classes.number}
                        type="number"
                        name="deductions"
                        value={isDataInLS ? formData.deductions : ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={18}
                      style={{ fontSize: "2vmin", marginBottom: 2 }}
                    >
                      <div className="mb-2">
                        <span className="fw-bold">(c) Extra withholding. </span>{" "}
                        Enter any additional tax you want withheld each pay
                        period..........
                      </div>
                    </Col>
                    <Col span={2} className={`mx-0 ${classes.colAllignBottom}`}>
                      4(c)
                    </Col>
                    <Col span={4} className={classes.colAllignBottom}>
                      ${" "}
                      <input
                        className={classes.number}
                        type="number"
                        name="extraWithHolding"
                        value={isDataInLS ? formData.extraWithHolding : ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider className={classes.customDivider} />
              <Row>
                <Col span={3} className={classes.colBorderRight}>
                  <div
                    className={`pt-2 fw-bold`}
                    style={{ fontSize: "1.5vmin" }}
                  >
                    Step 5: <br /> Sign <br /> Here
                  </div>
                </Col>
                <Col span={21} style={{ fontSize: "2vmin", padding: 5 }}>
                  <div>
                    Under penalties of perjury, I declare that this certificate,
                    to the best of my knowledge and belief, is true, correct,
                    and complete.
                  </div>
                  <Row>
                    <Col span={18}>
                      <SignatureCanvas
                        ref={(ref) => setSignatureRef(ref)}
                        canvasProps={canvasProps}
                      />
                      <div style={{ fontSize: "2.4vmin", marginBottom: 2 }}>
                        <span className="fw-bold">Employee's signature</span>{" "}
                        (This form is not valid unless you sign it.)
                        <button
                          // style={{ marginLeft: "10vmin" }}
                          type="button"
                          onClick={handleClearSignature}
                        >
                          Clear
                        </button>
                      </div>
                    </Col>
                    <Col span={6}>
                      <input
                        className={classes.text}
                        type="date"
                        name="signDate"
                        value={isDataInLS ? formData.signDate : ""}
                        onChange={handleChange}
                      />
                      <div
                        className="fw-bold"
                        style={{ fontSize: "2.4vmin", marginBottom: 2 }}
                      >
                        Date
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider className={classes.customDivider} />
              <Row>
                <Col span={3} className={classes.colBorderRight}>
                  <div
                    className={`pt-2 fw-bold`}
                    style={{ fontSize: "1.5vmin" }}
                  >
                    Employers <br /> Only
                  </div>
                </Col>
                <Col span={12} className={classes.colBorderRightEmp}>
                  <div>Employer's name and address</div>
                  <input
                    className={classes.text}
                    type="text"
                    name="EmpfullName"
                    value={`${basicFormData.fName}  ${basicFormData.lName}`}
                    readOnly
                    onChange={handleChange}
                  />
                  &nbsp;
                  <input
                    className={classes.text}
                    type="text"
                    name="address"
                    value={basicFormData.address}
                    readOnly
                    onChange={handleChange}
                  />
                </Col>
                <Col span={4} className={classes.colBorderRightEmp}>
                  <div>First date of employment</div>
                  <input
                    className={classes.text}
                    type="date"
                    name="EmploymentData"
                    value={isDataInLS ? formData.EmploymentData : ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col span={5} className={classes.colBorderRightEmp}>
                  <div>Employer identification number (EIN)</div>
                  <input
                    className={classes.number}
                    type="number"
                    name="EIN"
                    value={isDataInLS ? formData.EIN : ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Divider className={classes.customDivider} />
              <Row>
                <Col span={14} style={{ fontSize: "1.8vmin" }}>
                  <span className="fw-bold">
                    {" "}
                    For Privacy Act and Paperwork Reduction Act Notice, see page
                    3.
                  </span>
                </Col>
                <Col span={5} style={{ fontSize: "1.8vmin" }}>
                  Cat. No. 10220Q
                </Col>
                <Col
                  span={5}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    fontSize: "1.8vmin",
                  }}
                >
                  Form <span className="fw-bold"> W-4</span> (2023)
                </Col>
              </Row>
            </div>

            {/* Page2 */}

            <div className={classes.page2}>
              <img src={page2P1} alt="Image1" className={classes.page2Image} />

              {/* <Row justify={"space-between"} >
                <Col span={12} style={{ fontSize: "1.8vmin" }}>Form w-4 (2023)</Col>
                <Col span={2} style={{ fontSize: "1.8vmin" }}>Page <span className="fw-bold" style={{ fontSize: "2.2vmin" }}> 2</span></Col>
                </Row>
                <Divider className={classes.customDivider} />
                <Row gutter={16} className="mt-3" >
                  <Col span={12} style={{ fontSize: "2vmin" }}>
                    <span className="fw-bolder"  style={{ fontSize: "3vmin" }}>General Instructions</span>
                    <div>Section references are to the Internal Revenue Code.</div>
                  </Col>
                  <Col span={12} style={{ fontSize: "2vmin" }}>
                    <span className="fw-bolder"  style={{ fontSize: "3vmin" }}>Specific Instructions</span>
                    <div><span className="fw-bold">Step 1(c)</span>. Check your anticipated filing status. This will determine the standard deduction and tax rates used to compute your withholding..</div>
                  </Col>
                </Row> */}
            </div>

            {/* Page 3 */}
            <div className={classes.page2}>
              <div>
                <img
                  src={page3P1}
                  alt="Image3"
                  className={classes.page2Image}
                />
              </div>
              <Row>
                <Col span={19}>
                  {" "}
                  <img
                    src={page3P2}
                    alt="Image4"
                    className={classes.page2Image}
                  />
                </Col>
                <Col span={5}>
                  <Row style={{ display: "flex", flexDirection: "row" }}>
                    {page3Fields.map((item) => (
                      <Col span={24}>
                        <Row
                          className={
                            item.text === "1" || item.text === "2b"
                              ? classes.for1
                              : item.text === "2a"
                              ? classes.for2a
                              : item.text === "2c"
                              ? classes.for2c
                              : item.text === "3"
                              ? classes.for3
                              : classes.for4
                          }
                        >
                          <Col span={3} className={classes.page3Content}>
                            <span className="fw-bold">{item.text} &nbsp;</span>
                          </Col>
                          <Col span={20} className={classes.page3Content}>
                            $
                            <input
                              className={classes.Page3number}
                              type="number"
                              name={item.name}
                              value={isDataInLS ? formData[item.name] : ""}
                              onChange={handleChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <div>
                <img
                  src={page3P3}
                  alt="Image5"
                  className={classes.page2Image}
                />
              </div>
              <Row>
                <Col span={19}>
                  {" "}
                  <img
                    src={page3P4}
                    alt="Image6"
                    className={`mt-2 ${classes.page2Image}`}
                  />
                </Col>
                <Col span={5}>
                  <Row style={{ display: "flex", flexDirection: "row" }}>
                    {page3P2Fields.map((item) => (
                      <Col span={24}>
                        <Row
                          className={
                            item.text === "1" || item.text === "3"
                              ? classes.for13
                              : item.text === "5"
                              ? classes.for5
                              : classes.forRemaining
                          }
                        >
                          <Col span={3} className={classes.page3Content}>
                            <span className="fw-bold">{item.text} &nbsp;</span>
                          </Col>
                          <Col span={20} className={classes.page3Content}>
                            $
                            <input
                              className={classes.Page3number}
                              type="number"
                              name={item.name}
                              value={isDataInLS ? formData[item.name] : ""}
                              onChange={handleChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>

              <div>
                <img
                  src={page3P5}
                  alt="Image6"
                  className={`mt-4 ${classes.page2Image}`}
                />
              </div>
            </div>

            {/* page 4 */}
            <div className={classes.page2}>
              <img
                src={page4}
                alt="Image7"
                className={`pe-1 ${classes.page2Image}`}
              />
            </div>
          </div>
        ) : (
          <W9Form
            basicFormData={basicFormData}
            canvasProps={canvasProps}
            allWorkforceData={allWorkforceData}
          />
        )}
      </div>

      <div>
        {!isAdmin ? (
          <div>
            <div className="d-flex justify-content-between p-3">
              <Buttons
                type={"dashed"}
                title={"Download PDF"}
                onClick={handleDownloadAndUpload}
              />
              {userData?.formStatus === 0 ? (
                <Buttons
                  type={"primary"}
                  title={"Next"}
                  size={"large"}
                  onClick={handleNext}
                />
              ) : (
                <div className="bg-success rounded p-2 text-white d-flex ">
                  <span className="mx-2">
                    <CheckCircleFilled style={{ fontSize: "1.3rem" }} />
                  </span>
                  Your Form has Been Submitted{" "}
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default W4Form;
