import React, { useEffect, useState } from "react";
import Forms from "../Forms/Forms";
import { Col } from "antd";
import { inputFields, inputFieldsForMobile } from "../../Utils/dataUtils";
import { useMediaQuery } from "@mui/material";

const BasicForm = ({ onFinish, path, formData, allWorkforceData, user }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const fields = isMobile ? inputFieldsForMobile : inputFields;
  const empFields = fields.filter(
    (items) => items.type && items.type.includes("emp")
  );
  const compFields = fields.filter(
    (items) => items.type && items.type.includes("comp")
  );
  const IndividFields = fields.filter(
    (items) => items.type && items.type.includes("ind")
  );

  return (
    <div className="mt-4">
      <Col span={24}>
        <div className="form-heading">
          <p>Personal Information</p> {isMobile ? "" : <p>Emergency Contact</p>}{" "}
        </div>
      </Col>
      <Forms
        inputFields={
          path === "/employee"
            ? empFields
            : path === "/individual"
            ? IndividFields
            : compFields
        }
        onFinish={onFinish}
        title={"basic-form"}
        basicForm={formData}
        allWorkforceData={allWorkforceData}
      />
    </div>
  );
};

export default BasicForm;
