import { Button, Form, Input, Modal, Radio, Select } from "antd";
import React, { useState } from "react";
import Loader from "../Loader/Loader";
import { Collapse } from "@mui/material";
import Forms from "../Forms/Forms";

const Modals = (props) => {
  const [selected, setSelected] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    title,
    isModalOpen,
    handleCancel,
    inputFields,
    selectWorkforce,
    selectType,
    onFinish,
    isloading,
  } = props;

  return (
    <Modal
      title={title}
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      className={"dropdown-toggle-split m-auto footer-remove pb-5"}
      style={{ zIndex: "100px", top: "-30px" }}
      width={500}
    >
      {isloading ? (
        <Loader />
      ) : (
        <Forms
          inputFields={inputFields}
          onFinish={onFinish}
          showDropdown={showDropdown}
          selected={selected}
          selectType={selectType}
          selectWorkforce={selectWorkforce}
          setSelected={setSelected}
          setShowDropdown={setShowDropdown}
        />
        // <Form
        //   className="modal-form"
        //   name="basic"
        //   onFinish={onFinish}
        //   autoComplete="off"
        // >
        //   {inputFields.map((item) => (
        //     // <Col span={item.name === "name" && !register ? 0 : 24}>
        //     <Collapse in={item.name === "workforceType" ? showDropdown : true}>
        //       <Form.Item
        //         style={{ fontFamily: "Poppins" }}
        //         className={
        //           item.name === "contracterType" && selected !== "Contractor"
        //             ? "visually-hidden"
        //             : ""
        //         }
        //         labelCol={{
        //           span: 8,
        //         }}
        //         wrapperCol={{
        //           span: 27,
        //         }}
        //         key={item.name}
        //         label={item.label}
        //         name={[item.name]}
        //         rules={[
        //           {
        //             required:
        //               selected === "Contractor"
        //                 ? true
        //                 : item.name === "contracterType"
        //                 ? false
        //                 : true,
        //             type: item.name === "email" ? item.name : "",
        //             message:
        //               item.name === "email"
        //                 ? `Invalid Email Format`
        //                 : `Enter your ${item.label}!`,
        //           },
        //         ]}
        //       >
        //         {item.name === `password` ? (
        //           <Input.Password placeholder={`Enter your ${item.label}`} />
        //         ) : item.name === "role" ? (
        //           <Radio.Group>
        //             <Radio.Button value="Admin">Admin</Radio.Button>
        //             {/* <Radio.Button value="User">User</Radio.Button> */}
        //           </Radio.Group>
        //         ) : item.isSelect ? (
        //           <Select
        //             placeholder={`Select ${item.label}`}
        //             onChange={(value) => {
        //               if (item.name === "workforce") {
        //                 setSelected(value);
        //                 setShowDropdown(true);
        //               }
        //             }}
        //           >
        //             {(item.name === "contracterType"
        //               ? selectType
        //               : selectWorkforce
        //             )?.map((data) => (
        //               <Select.Option key={data.id} value={data.option}>
        //                 {data.option}
        //               </Select.Option>
        //             ))}
        //           </Select>
        //         ) : (
        //           <Input placeholder={`Enter ${item.label}`} />
        //         )}
        //       </Form.Item>
        //     </Collapse>
        //     // </Col>
        //   ))}

        //   <Form.Item
        //     wrapperCol={{
        //       offset: 19,
        //       span: 16,
        //     }}
        //   >
        //     <Button type="primary" htmlType="submit">
        //       Submit
        //     </Button>
        //   </Form.Item>
        // </Form>
      )}
    </Modal>
  );
};

export default Modals;
