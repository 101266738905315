import { Button, Form, Input, Typography } from "antd";
import classes from "./auth-form.module.css";
import logo from "../../Asset/Images/UGALogo.png";
import { useDispatch } from "react-redux";
import { sendloginCred } from "../../Store/ApiActions/loginApi";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { createAdmin } from "../../Store/ApiActions/AdminApi";
import { signUp } from "../../Store/ApiActions/signupApi";

const { Title } = Typography;

const inputField = [
  {
    name: "username",
    label: "Name",
    render: (isMember) => isMember,
  },
  {
    name: "email",
    label: "Email",
    render: () => true,
  },
  {
    name: "password",
    label: "Password",
    render: () => true,
  },
];

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMember, setIsMember] = useState(false);

  const handleLogin = () => {
    setIsMember(true);
  };
  const handleSignup = () => {
    setIsMember(false);
  };
  const [form] = Form.useForm();
  // var role = "Admin";
  const onFinish = async (values) => {
    console.log(values);
    const { email, password, username } = values;
    console.log(email, password, username);
    if ((email === "" && password === "") || (isMember && username === "")) {
      return;
    }

    try {
      if (isMember) {
        const res = await dispatch(signUp({ email, password, username }));
        console.log(res);
        if (res.payload.status === true) {
          toast.success("Account Created Successfully");
          setIsMember(false);
          form.resetFields();
        }
      } else {
        const result = await dispatch(sendloginCred({ email, password }));
        console.log(result);
        if (result.payload.status === true) {
          toast.success(result.payload.message);
          const getToken = result.payload.data.token;
          localStorage.setItem("token", getToken);
          form.resetFields();
        } else {
          toast.error(result.payload.message);
        }
      }
    } catch (err) {}
  };

  return (
    <div className={classes.container}>
      <div
        className={`${classes.loginBox} `}
        // style={{ margin: register ? "4%" : "7%" }}
      >
        <div className="text-center">
          <img
            src={logo}
            style={{ filter: "contrast(0.5)" }}
            alt="logo"
            height={"100px"}
            width={"150px"}
            // className="img-fluid"
          />
        </div>
        <Title
          level={2}
          className="mt-2 text-center"
          style={{ fontFamily: "Poppins", color: "#4096FF" }}
        >
          {isMember ? "Sign Up" : "Sign In"}
        </Title>
        <Form
          className={classes.loginForm}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
        >
          {inputField.map(
            (item) =>
              // Check the render function to conditionally render the input field
              item.render(isMember) && (
                <Form.Item
                  style={{ fontFamily: "Poppins" }}
                  labelCol={{
                    span: item.name === "email" || item.name === "username"  ? 5 : 7,
                  }}
                  wrapperCol={{
                    span: 27,
                  }}
                  key={item.name}
                  label={item.label}
                  name={[item.name]}
                  rules={[
                    {
                      required: true,
                      type: item.name === "email" ? item.name : "",
                      message:
                        item.name === "email"
                          ? `Invalid Email Format`
                          : `Enter your ${item.label}!`,
                    },
                  ]}
                >
                  {item.name === `password` ? (
                    <Input.Password
                      size="large"
                      placeholder={`Enter your ${item.label}`}
                    />
                  ) : (
                    <Input
                      size="large"
                      placeholder={`Enter your ${item.label}`}
                    />
                  )}
                </Form.Item>
              )
          )}

          <Form.Item
            wrapperCol={{
              span: 27,
            }}
          >
            {isMember ? (
              <Button type="primary" htmlType="submit" block size="large">
                Signup
              </Button>
            ) : (
              <Button type="primary" htmlType="submit" block size="large">
                Login
              </Button>
            )}
          </Form.Item>
        </Form>

        {isMember ? (
          <div className="text-center">
            Already Member? <Link onClick={handleSignup}>Login</Link>
          </div>
        ) : (
          <div className="text-center">
            Not a member Yet? <Link onClick={handleLogin}>Signup</Link>
          </div>
        )}
      </div>
    </div>
  );
};
export default Login;
