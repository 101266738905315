import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
  LogoutOutlined,
  HomeOutlined,
  AuditOutlined,
  DownOutlined,
  UpOutlined,
  BankOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import LogoutIcon from "@mui/icons-material/Logout";
import { Layout, Menu, theme } from "antd";
import classes from "./Layouts.module.css";
import Admin from "../../Pages/Admin/Admin";
import Workforce from "../../Pages/Workforce/Workforce";
import Buttons from "../Button/Buttons";
import logo from "../../Asset/Images/logo.png";
import { jwtDecode } from "jwt-decode";

import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import WorkforcePortal from "../../Pages/WorkforcePortal/WorkforcePortal";
import { toast } from "react-toastify";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Fade,
  Modal,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { Image } from "../../Pages/Image/Image";
import { logoutSuccess } from "../../Store/ApiActions/loginApi";
import { useDispatch, useSelector } from "react-redux";
import SignUp from "../Auth-forms/SignUp";
import Home from "../../Pages/Home/Home";
import { getWorkforce } from "../../Store/ApiActions/workforceApi";
import UserHistory from "../UserHistory/UserHistory";

const sideBarList = [
  {
    id: 1,
    Label: "Home",
    url: "/",
    icon: <HomeOutlined />,
    access: ["Admin", "SuperAdmin"],
  },
  {
    id: 2,
    Label: "Admin",
    url: "/admin",
    icon: <UserOutlined />,
    access: ["SuperAdmin"],
  },
  {
    id: 3,
    Label: "Work Force",
    url: "/workforce",
    icon: <UsergroupAddOutlined />,
    access: ["Admin", "SuperAdmin"],
  },
  {
    id: 8,
    Label: "Status",
    url: "/history",
    icon: <AuditOutlined />,
    access: ["Employee", "Individual", "Company"],
  },
  {
    id: 4,
    Label: "Form",
    url: "/form",
    icon: <AuditOutlined />,
    downIcon: <DownOutlined />,
    upIcon: <UpOutlined />,
    access: ["Admin", "SuperAdmin", "Employee", "Company", "Individual"],
  },
  {
    id: 5,
    Label: "Employee",
    url: "/employee",
    icon: <UserOutlined />,
    isdropdown: true,
    access: ["Admin", "SuperAdmin", "Employee"],
  },
  {
    id: 6,
    Label: "Individual",
    url: "/individual",
    icon: <IdcardOutlined />,
    isdropdown: true,
    access: ["Admin", "SuperAdmin", "Individual"],
  },
  {
    id: 7,
    Label: "Company",
    url: "/company",
    icon: <BankOutlined />,
    isdropdown: true,
    access: ["Admin", "SuperAdmin", "Company"],
  },
  // {
  //   id: 8,
  //   Label: "Image",
  //   url: "/image",
  //   icon: <AuditOutlined />,
  //   access: ["SuperAdmin"],
  // },
];
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
  },
}));

const { Header, Sider, Content } = Layout;
const Layouts = ({ user }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [value, setValue] = useState({ collapsed: false, activeContent: "1" });
  const [dropdown, setDropdown] = useState(false);
  const [isAdmin, setIsAdmin] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const allWorkforceData = useSelector(
    (state) => state.workforce?.getworkforceData
  );

  useEffect(() => {
    dispatch(getWorkforce());
  }, [dispatch]);

  useEffect(() => {
    if (isMobile) {
      setValue((prev) => ({ ...prev, collapsed: true }));
    }
  }, [isMobile]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  var decoded;

  function decoder() {
    var token = localStorage.getItem("token");
    decoded = jwtDecode(token);
    console.log("decoded ==>", decoded);

    if (decoded == null) {
      navigate("/");
    } else {
      localStorage.setItem("username", decoded.tokenData.username);
      localStorage.setItem("userId", decoded.tokenData.id);
      localStorage.setItem("userEmail", decoded.tokenData.email);
      var user = localStorage.getItem("username");
      setIsAdmin(decoded.tokenData.role);
      return user;
    }
  }

  React.useEffect(() => {
    decoder();
  }, []);

  const handleSmallScreen = () => {
    if (isMobile) {
      console.log("checked");
      setValue((prev) => ({ ...prev, collapsed: true }));
    }
  };

  const logoutHandle = ({ user }) => {
    dispatch(logoutSuccess());

    if (localStorage?.getItem("token")) {
      console.log("here");
    } else {
      toast.success("Logging Out");

      navigate("/");
      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    }
  };

  const style = {
    color: "blue",
  };

  // Get name and email form Local storage
  var userName = localStorage.getItem("username");
  var email = localStorage.getItem("userEmail");
  const activationStatus = allWorkforceData?.find(
    (item) => item.email === email
  );
  const userStatus = activationStatus?.activeStatus;
  const userImage = activationStatus?.profile;
  console.log(activationStatus);

  return (
    <div className={value.collapsed ? classes.layoutShrink : classes.layout}>
      <Layout>
        <Sider
          width="140px"
          trigger={null}
          collapsible
          collapsed={value.collapsed}
        >
          <div className="demo-logo-vertical" />
          <div className={`${classes.heading}`}>
            {value.collapsed ? (
              <div className="h5 mt-3 p-1">HRM</div>
            ) : (
              "Human Resource Management"
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            // onSelect={(item) => handleMenuClick(item.key)}
          >
            {sideBarList?.map((btns) =>
              !dropdown && btns.isdropdown ? (
                ""
              ) : (
                <div
                  key={btns.id}
                  style={
                    btns.access.includes(isAdmin)
                      ? { display: "initial" }
                      : { display: "none" }
                  }
                >
                  <li
                    key={btns.id}
                    className={
                      location.pathname === btns.url
                        ? `ant-menu-item ant-menu-item-selected ${classes.selectedTab}`
                        : "ant-menu-item"
                    }
                    style={{
                      // Add transition only for elements with isdropdown: true
                      ...(btns.isdropdown && {
                        transition: "margin-top 0.9s, font-size 0.3s",
                      }),
                      // Add other styles
                      marginTop: btns.isdropdown ? "-8px" : "15px",
                      fontSize: btns.isdropdown ? "12px" : undefined,
                      // marginLeft: btns.isdropdown && !value.collapsed ? "25px" : undefined,
                    }}
                  >
                    <Link
                      onClick={
                        (btns.downIcon &&
                          (() => {
                            setDropdown(!dropdown);
                          })) ||
                        handleSmallScreen
                      }
                      style={{ textDecoration: "none" }}
                      to={btns.url}
                    >
                      <span
                        className={
                          btns.isdropdown
                            ? "me-2"
                            : "anticon anticon-upload ant-menu-item-icon"
                        }
                      >
                        {btns.icon}
                      </span>
                      <span className="ant-menu-title-content">
                        {value.collapsed ? "" : btns.Label}
                      </span>
                      <span className="mx-3">
                        {dropdown ? btns.upIcon : btns.downIcon}
                      </span>
                    </Link>
                  </li>
                </div>
              )
            )}
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              color: "#969FA7",
              // position:'fixed'
              // background: colorBgContainer,
            }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <Buttons
                  type={"text"}
                  icon={
                    value.collapsed ? (
                      <MenuUnfoldOutlined />
                    ) : (
                      <MenuFoldOutlined />
                    )
                  }
                  onClick={() =>
                    setValue((prev) => ({
                      ...prev,
                      collapsed: !value.collapsed,
                    }))
                  }
                  style={{
                    fontSize: "3.6vmin",
                    // width: 64,
                    height: 64,
                    color: "white",
                  }}
                  className="mx-2"
                />
                {/* <img
                  src={logo}
                  alt="image"
                  style={{marginLeft:"1vmin"}}
                  className={`${classes.img} img-fluid`}
                /> */}
              </div>
              {["Employee", "Company", "Individual"].includes(isAdmin) ? (
                <>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    className={classes.badgeCircle}
                  >
                    <Avatar
                      onClick={handleModalOpen}
                      sx={
                        isMobile
                          ? { height: "40px", width: "40px" }
                          : { height: "50px", width: "50px" }
                      }
                    >
                      <img
                        src={userImage}
                        className="img-fluid"
                        style={{ height: "90%" }}
                      />
                    </Avatar>
                  </StyledBadge>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalOpen}
                    onClose={handleModalClose}
                    closeAfterTransition
                  >
                    <Fade in={modalOpen}>
                      <Box className={classes.profileModal}>
                        <Typography
                          id="transition-modal-title"
                          // sx={{
                          //   display: "flex",
                          //   justifyContent: "center",
                          //   gap: 1,
                          // }}
                        >
                          <div className={classes.profileText}>
                            HEY! {userName.toUpperCase()}
                          </div>
                          <div
                            className={classes.profileText}
                            style={{ fontSize: "2vmin" }}
                          >
                            {userStatus === 1 ? (
                              <span style={{ color: "#00bf5c" }}>
                                You Are Active Now.
                              </span>
                            ) : userStatus === 2 ? (
                              <span style={{ color: "#9e0000" }}>
                                Your Account Has Been Deactivated. Please
                                contact support for assistance.
                              </span>
                            ) : (
                              <span style={{ color: "#aba100" }}>
                                Wait for Activation.
                              </span>
                            )}
                          </div>
                        </Typography>
                        {/* <div className="text-uppercase">{userName}</div> */}
                        <Typography
                          id="transition-modal-description"
                          sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            color="inherit"
                            onClick={logoutHandle}
                            style={{ cursor: "pointer" }}
                          >
                            <LogoutIcon
                              fontSize={isMobile ? "small" : "medium"}
                              sx={{ marginRight: 1 }}
                            />
                            <span className={classes.profileText}>Log Out</span>
                          </div>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                </>
              ) : (
                <div
                  onClick={logoutHandle}
                  className={isMobile ? "mx-4" : "mx-4 pe-3"}
                >
                  <LogoutOutlined
                    style={{
                      fontSize: "3.3vmin",
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
            </div>
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              overflow: "hidden",
              // minHeight: 1000,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="/" element={<Home isAdmin={isAdmin} />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/workforce" element={<Workforce />} />
              <Route
                path="/form"
                element={
                  <WorkforcePortal path={location.pathname} isAdmin={isAdmin} />
                }
              />
              <Route
                path="/employee"
                element={
                  <WorkforcePortal
                    path={location.pathname}
                    iscollapsed={value.collapsed}
                  />
                }
              />
              <Route
                path="/individual"
                element={
                  <WorkforcePortal
                    path={location.pathname}
                    iscollapsed={value.collapsed}
                  />
                }
              />
              <Route
                path="/company"
                element={
                  <WorkforcePortal
                    user={user}
                    path={location.pathname}
                    iscollapsed={value.collapsed}
                  />
                }
              />
              <Route path="/history" element={<UserHistory />} />
              <Route path="/signup" element={<SignUp />} />

              {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default Layouts;
