import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modals from "../../Components/Modal/Modals";
import { toast } from "react-toastify";
import {
  createAdmin,
  deleteAdmin,
  updateAdmin,
} from "../../Store/ApiActions/AdminApi";
import { useDispatch, useSelector } from "react-redux";
import { getAdmin } from "../../Store/ApiActions/AdminApi";
import Tables from "../../Components/Tables/Tables";
import Buttons from "../../Components/Button/Buttons";
import { adminInputFields as inputFields } from "../../Utils/dataUtils";

const Admin = () => {
  const [isloading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();
  const allAdminData = useSelector((state) => state.admin?.getAdminData);
  console.log("login Data>>>", allAdminData);

  useEffect(() => {
    if (allAdminData) {
      setTableData(allAdminData);
    }
  }, [allAdminData]);

  useEffect(() => {
    dispatch(getAdmin());
  }, [dispatch]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    console.log("checked2");
    // form.resetFields();
    setIsModalOpen(false);
  };
  const handleSubmit = async (values) => {
    console.log("Modal Data >>>", values);
    setIsLoading(true);
    const response = await dispatch(createAdmin(values));
    console.log(response);
    if (response.payload.status) {
      dispatch(getAdmin());
      setTimeout(() => {
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success("Added Succesfuly");
      }, 2000);
    }
    // form.resetFields();
  };
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    values = { ...values, id: row.original.id };

    if (!Object.keys(validationErrors).length) {
      exitEditingMode(); //required to exit editing mode and close modal
      const updatedResult = await dispatch(updateAdmin(values));
      console.log(updatedResult);

      if (updatedResult.payload.status === 200) {
        dispatch(getAdmin());
        toast.success("Updated Succesfuly");
      }
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("username")}`
        )
      ) {
        return;
      }
      const deletedData = await dispatch(deleteAdmin(row.original));
      console.log(deletedData);
      if (deletedData.payload.status) {
        dispatch(getAdmin());
        toast.success("Deleted Succesfuly");
      }
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          setValidationErrors({
            ...validationErrors,
            // [cell.id]: `${cell.column.columnDef.header} is required`,
          });
        },
      };
    },
    [validationErrors]
  );
  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "id",
      //   header: "Id",
      //   enableEditing: false, //disable editing on this column
      //   size: 50,
      // },
      {
        accessorKey: "username",
        header: "User Name",
        size: 50,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 50,
      },
      {
        accessorKey: "password",
        header: "Password",
        size: 50,
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 50,
      },
    ],
    [getCommonEditTextFieldProps, tableData]
  );
  return (
    <div>
      <Buttons
        title={"Create Admin"}
        className={"m-2 mx-2"}
        type={"primary"}
        onClick={handleModalOpen}
      />
      <Modals
        isModalOpen={isModalOpen}
        handleCancel={handleModalClose}
        onFinish={handleSubmit}
        inputFields={inputFields}
        isloading={isloading}
        // form={form}
        title={"Create Admin"}
      />

      <Tables
        columns={columns}
        tableData={tableData}
        handleDeleteRow={handleDeleteRow}
        handleSaveRowEdits={handleSaveRowEdits}
        handleCancelRowEdits={handleCancelRowEdits}
      />
    </div>
  );
};

export default Admin;
