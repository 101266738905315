// import axios from "axios";

// const baseURL = "http://localhost:5000/api";

// const token = localStorage.getItem("token");
// console.log(token);

// export const publicRequest = axios.create({
//   baseURL: baseURL,
// });

// export const userRequest = axios.create({
//   baseURL: baseURL,

//   headers: {
//     token: `Bearer ${token}`,
//   },
// });

import axios from "axios";

// const baseURL = "http://localhost:5000/api";
const baseURL = "https://hrm-b.digitalcoverages.com/api";

// Function to get the token from localStorage
const getToken = () => {
  // Replace with your actual localStorage retrieval logic
  return localStorage.getItem("token");
};

export const publicRequest = axios.create({
  baseURL: baseURL,
});

export const userRequest = axios.create({
  baseURL: baseURL,
});

// Add an interceptor to dynamically set the Authorization header before each request
userRequest.interceptors.request.use(
  (config) => {
    // Set the Authorization header with the Bearer token
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
