import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import classes from "./Cards.module.css";
import { IconButton } from "@mui/material";

const Cards = ({ title, icon, url, access, isAdmin }) => {
  return (
    <>

      <Link style={!access.includes(isAdmin) ? { display: "none" } : { textDecoration: "none", color: "#0a5d65" }} to={url}>
      {/* <Link style={{ textDecoration: "none", color: "#0a5d65" }} to={url}> */}

        <div
          className={`${classes.featureItem} text-center my-2 col-lg-3 col-md-4 py-4 mb-4`}
          // key={title}
        >
          <div className="card-body py-4">
            <label className="main-content-label">
              <IconButton className={`${classes.featureIcon} my-2 `}>
                {icon}
              </IconButton>
              <br />
              <h5 className={`${classes.featureTitle} py-2  fw-bold`}>
                {title}
              </h5>
            </label>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Cards;
