import { createSlice } from "@reduxjs/toolkit";
import { createWorkforce, getWorkforce } from "../ApiActions/workforceApi";

const initialState = {
  workforceData: [],
  getworkforceData: [],
  isFecthing: false,
  error: "",
};

const workforce = createSlice({
  name: "workforce",
  initialState,
  reducers: {},
  extraReducers: (obj) => {
    //Create
    obj.addCase(createWorkforce.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(createWorkforce.fulfilled, (state, action) => {
      console.log(action);

      state.workforceData = action.payload;
      state.isFecthing = false;
    });
    obj.addCase(createWorkforce.rejected, (state, action) => {
      state.isFecthing = false;
      state.workforceData = [];
      state.error = action.error;
    });

    //Get
    obj.addCase(getWorkforce.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(getWorkforce.fulfilled, (state, action) => {
      console.log("Workforce reducer>>>", action.payload);
      state.getworkforceData = action.payload;
      state.isFecthing = false;
    });
    obj.addCase(getWorkforce.rejected, (state, action) => {
    //   state.getworkforceData = [],
      state.isFecthing = false;
      state.error = action.error;
    });
  },
});

export default workforce.reducer;
