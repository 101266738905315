import {
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import Buttons from "../Button/Buttons";
import { UploadOutlined } from "@ant-design/icons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMediaQuery } from "@mui/material";
import { jwtDecode } from "jwt-decode";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Forms = (props) => {
  const {
    inputFields,
    onFinish,
    showDropdown,
    selected,
    selectType,
    selectWorkforce,
    setSelected,
    setShowDropdown,
    title,
    basicFormData,
    basicForm,
    allWorkforceData,
  } = props;

  console.log("workforce data from basic form ==> ", allWorkforceData);

  const [userData, setUserData] = useState(null);
  console.log("Conditioon", userData);

  const personalInfo = [
    {
      name: "fName",
      label: "First Name",

      input: userData?.fName,
    },
    {
      name: "lName",
      label: "Last Name",
      input: userData?.lName,
    },
    {
      name: "ssn",
      label: "SSN #",
      input: userData?.ssn,
    },
    {
      name: "phone",
      label: "Phone",
      input: userData?.phone,
    },
    {
      name: "address",
      label: "Address",
      input: userData?.address,
    },
  ];

  const emergencyInfo = [
    {
      name: "contactName1",
      label: "Contact 1 Name",
      input: userData?.contactName1,
    },
    {
      name: "contactPhone1",
      label: "Contact 1 Phone",
      input: userData?.contactPhone1,
    },

    {
      name: "contactName2",
      label: "Contact 2 Name",
      input: userData?.contactName2,
    },
    {
      name: "contactPhone2",
      label: "Contact 2 Phone",
      input: userData?.contactPhone2,
    },
  ];

  const bankingInfo = [
    {
      name: "accountHolder",
      label: "Account Holder",
      input: userData?.accountHolder,
    },
    {
      name: "bankName",
      label: "Bank Name",
      input: userData?.bankName,
    },
    {
      name: "routing",
      label: "Routing #",
      input: userData?.routing,
    },
    {
      name: "confirmRouting",
      label: "Confirm Routing #",
      input: userData?.confirmRouting,
    },
    {
      name: "account",
      label: "Account #",
      input: userData?.account,
    },
    {
      name: "confirmAccount",
      label: "Confirm Account #",
      input: userData?.confirmAccount,
    },
    {
      name: "profile",
      label: "Picture for Profile",
      // input: userData?.profile,
      input: <img src={userData?.profile} className="img-fluid" alt="No Img" />,
    },
  ];

  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isNotImg, setIsNotImg] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
  });
  const [uploadImage, setuploadImage] = useState({
    uploadData: {},
    uploadImage: {},
    isImage: false,
    isError: false,
  });
  const [isAdmin, setIsAdmin] = React.useState(null);
  console.log(isAdmin);

  console.log("Error>>>>", uploadImage.isError);

  const getUserData = () => {
    const userEmail = localStorage.getItem("userEmail");

    const loginUser = allWorkforceData?.find(
      (item) => userEmail === item.email
    );
    setUserData(loginUser);
  };

  useEffect(() => {
    getUserData();
  }, [allWorkforceData]);

  var decoded;

  function decoder() {
    var token = localStorage.getItem("token");
    decoded = jwtDecode(token);
    console.log("decoded ==>", decoded);

    if (decoded == null) {
    } else {
      // localStorage.setItem("username", decoded.tokenData.username);
      // localStorage.setItem("userId", decoded.tokenData.id);
      // localStorage.setItem("userEmail", decoded.tokenData.email);
      var user = localStorage.getItem("username");
      setIsAdmin(decoded.tokenData.role);
      return user;
    }
  }

  React.useEffect(() => {
    decoder();
  }, []);

  // console.log("data in state>>>>", basicForm.length);
  const isGetName = basicFormData && Object.keys(basicFormData).length !== 0;
  const isDataInLS =
    basicForm?.profile && Object.keys(basicForm.profile).length !== 0;

  console.log(isDataInLS);

  if (isGetName && !formData.firstName && !formData.lastName) {
    const { fName, lName } = basicFormData;
    setFormData({ firstName: fName, lastName: lName });
  }
  const numberFields = [
    "ssn",
    "ein",
    "phone",
    "contactPhone1",
    "contactPhone2",
    "year",
    "routing",
    "confirmRouting",
    "account",
    "confirmAccount",
  ];

  const maxFileSize = 2; // in megabytes
  const acceptedFileTypes = ["image/jpeg", "image/png", "image/jpg"];

  // Define a function to check if the file size is within limits
  const isFileWithinSizeLimit = (file) => {
    return file.size / 1024 / 1024 < maxFileSize;
  };

  // Define a function to check if the file type is accepted
  const isFileTypeAccepted = (file) => {
    return acceptedFileTypes.includes(file.type);
  };

  const uploadProps = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    // action: "http://localhost:3001/api/incident/createIncident",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      console.log("file>>>", file);
      const isLtMaxSize = isFileWithinSizeLimit(file);
      const isFileTypeValid = isFileTypeAccepted(file);
      console.log(isFileTypeValid, isLtMaxSize);

      if (!isLtMaxSize) {
        message.error(`File must be smaller than ${maxFileSize} MB!`);
        return false;
      }

      if (!isFileTypeValid || !isLtMaxSize) {
        console.log("checked");
        setuploadImage((prev) => ({ ...prev, isError: true }));
      }
      if (!isFileTypeValid) {
        message.error(`Invalid file type! only accept JPG/PNG/Jpeg}`);
        return false;
      }
      if (isFileTypeValid && isLtMaxSize) {
        message.success(`Profile Uploaded`);
        return true;
      }
      // return true; // Proceed with upload
    },
    onChange: (info) => {
      const fileData = info.fileList[0]?.originFileObj;
      console.log("file data in attacment>>>", fileData);

      const isLtMaxSize = isFileWithinSizeLimit(fileData);
      const isFileTypeValid = isFileTypeAccepted(fileData);

      if (isLtMaxSize && isFileTypeValid) {
        getBase64(info.file.originFileObj, (url) => {
          setuploadImage({
            uploadData: fileData,
            uploadImage: url,
            isImage: true,
          });
        });
      }
      if (info.file.status !== "uploading") {
        console.log("file >>>", info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const showModal = () => {
    // props.form.resetFields();
    // setIsModalOpen(true);
  };

  const deleteImg = () => {
    setuploadImage({
      uploadData: {},
      uploadImage: {},
      isImage: false,
      isError: false,
    });
  };
  // useEffect(()=>{
  //   deleteImgInLS()
  // },[])

  const deleteImgInLS = (e) => {
    // Get current formData from localStorage
    const storedData = JSON.parse(localStorage.getItem("BasicForm"));

    // Remove the 'profile' property
    delete storedData?.profile;

    // Save the updated data back to localStorage
    localStorage.setItem("BasicForm", JSON.stringify(storedData));
    setIsNotImg(true);
  };

  return (
    <Form
      className={title === "basic-form" ? "mt-3" : "modal-form"}
      name="basic"
      onFinish={(values) => {
        onFinish(values, uploadImage.uploadData);
        // onFinish(values, uploadImage);
      }}
      autoComplete="off"
      key={title}
    >
      {userData?.formStatus === 0 ||
      isAdmin === "SuperAdmin" ||
      isAdmin === "Admin" ? (
        <Row gutter={16}>
          {inputFields.map((item) => (
            <Col
              span={
                title !== "basic-form" || item?.isFullspan
                  ? 24
                  : isMobile
                  ? 24
                  : 12
              }
            >
              {/* // <Collapse in={item.name === "workforceType" ? showDropdown : true}> */}
              {item.name === "contactName1" && isMobile && (
                <div className="form-heading my-4">Emergency Contact</div>
              )}

              {item.name === "startDate" && (
                <div className="form-heading my-4">Insurance Information</div>
              )}
              {item.name === "make" && (
                <div className="form-heading my-4">Vehicle Information</div>
              )}
              {item.name === "accountHolder" && (
                <div className="form-heading my-4">Banking information</div>
              )}
              <Form.Item
                style={{ fontFamily: "Poppins" }}
                className={
                  item.name === "contracterType" && selected !== "Contractor"
                    ? "visually-hidden"
                    : ""
                }
                labelCol={
                  title === "basic-form" && !item?.isFullspan
                    ? {
                        span: 9,
                      }
                    : item?.isFullspan
                    ? {
                        span: 10,
                      }
                    : {
                        span: 8,
                      }
                }
                wrapperCol={
                  title === "basic-form" && !item?.isFullspan
                    ? {
                        span: 14,
                      }
                    : item?.isFullspan
                    ? {
                        span: 8,
                      }
                    : {
                        span: 27,
                      }
                }
                key={item.name}
                label={item.label}
                name={[item.name]}
                initialValue={isDataInLS ? basicForm[item.name] || "" : ""}
                rules={
                  item.name === "profile" || item.name === "insuranceImage"
                    ? [{ required: true }]
                    : [
                        item.label === "Start Date" ||
                        item.label === "Expire Date"
                          ? {
                              type: "object",
                              required: false,
                              message: `Please select ${item.label}!`,
                            }
                          : {
                              required:
                                selected === "Contractor"
                                  ? true
                                  : item.name === "contracterType"
                                  ? false
                                  : false,
                              type: item.name === "email" ? item.name : "",
                              message:
                                item.name === "email"
                                  ? `Invalid Email Format`
                                  : `Enter your ${item.label}!`,
                            },
                        {
                          validator: (_, value) => {
                            // New validation rule for specific number fields
                            if (
                              numberFields.includes(item.name) &&
                              value &&
                              isNaN(Number(value))
                            ) {
                              return Promise.reject(`Enter only numeric value`);
                            }
                            return Promise.resolve();
                          },
                        },
                      ]
                }
              >
                {item.name === `password` ? (
                  <Input.Password placeholder={`Enter your ${item.label}`} />
                ) : item.name === "role" ? (
                  <Radio.Group>
                    <Radio.Button value="Admin">Admin</Radio.Button>
                    {/* <Radio.Button value="User">User</Radio.Button> */}
                  </Radio.Group>
                ) : item.isSelect ? (
                  <Select
                    // placeholder={`Select ${item.label}`}
                    onChange={(value) => {
                      if (item.name === "workforce") {
                        setSelected(value);
                        setShowDropdown(true);
                      }
                    }}
                  >
                    {(item.name === "contracterType"
                      ? selectType
                      : selectWorkforce
                    )?.map((data) => (
                      <Select.Option key={data.id} value={data.option}>
                        {data.option}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (item.name === "fName" && isGetName) ||
                  (item.name === "lName" && isGetName) ? (
                  <Input />
                ) : item.isblank ? (
                  ""
                ) : item.label === "Start Date" ||
                  item.label === "Expire Date" ? (
                  <DatePicker
                    className="date-select w-100"
                    placeholder={`Select ${item.label}`}
                  />
                ) : item.name === "profile" ||
                  item.name === "insuranceImage" ? (
                  (uploadImage.isImage && !uploadImage.isError) ||
                  (!isNotImg && isDataInLS) ? (
                    <Row
                      className={
                        uploadImage.isError ? "image-div-error" : "image-div"
                      }
                    >
                      {isDataInLS ? (
                        <Col
                          className="upload-col"
                          span={20}
                          onClick={showModal}
                        >
                          {basicForm?.profile?.file.name.length > 15
                            ? `${basicForm.profile.file.name.substring(
                                0,
                                12
                              )}.${basicForm.profile.file.type.substring(
                                6,
                                10
                              )}`
                            : basicForm.profile.file.name}
                        </Col>
                      ) : (
                        <Col
                          className="upload-col"
                          span={20}
                          onClick={showModal}
                        >
                          {uploadImage.isError
                            ? "Invalid format or size"
                            : uploadImage.uploadData.name.length > 15
                            ? `${uploadImage.uploadData.name.substring(
                                0,
                                12
                              )}.${uploadImage.uploadData.type.substring(
                                6,
                                10
                              )}`
                            : uploadImage.uploadData.name}
                        </Col>
                      )}
                      <Col
                        span={4}
                        onClick={isDataInLS ? deleteImgInLS : deleteImg}
                        className="delete-icon"
                      >
                        <DeleteOutlineIcon sx={{ fontSize: "16px" }} />
                      </Col>
                    </Row>
                  ) : (
                    <Upload
                      {...uploadProps}
                      className={uploadImage.isError ? "upload-error" : ""}
                    >
                      <Row className="upload-div">
                        <Col className="upload-col" span={21}>
                          Upload Image
                        </Col>
                        <Col className="upload-col" span={3}>
                          <UploadOutlined />
                        </Col>
                      </Row>
                    </Upload>
                  )
                ) : (
                  // <input type="file" onChange={(e) => setImage(e.target.files[0])}/>

                  <Input
                    disabled={userData?.formStatus === 1}
                    placeholder={`Enter ${item.label}`}
                  />
                )}
              </Form.Item>
              {/* // </Collapse> */}
            </Col>
          ))}
        </Row>
      ) : (
        <Form
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <div className="row justify-content-around">
            <div className="  col-lg-5 col-md-6 col-sm-12 text-center ">
              {/* <h5 className="mb-4" style={{ color: "#1677FF" }}>
                Personal Information
              </h5> */}
              <div className="">
                {personalInfo.map((item) => {
                  return (
                    <div className>
                      <Form.Item label={item.label} name={item.name}>
                        <div
                          style={{
                            height: "30px",
                            background: "#80808040",
                            border: " 1px solid #80808040  ",
                          }}
                          className=" text-left p-1 rounded"
                        >
                          {" "}
                          {item.input}
                        </div>
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="  col-lg-5 col-md-6 col-sm-12 text-center ">
              {/* <h5 className="mb-4" style={{ color: "#1677FF" }}>
                Emergency Contact
              </h5> */}
              <div className="">
                {emergencyInfo.map((item) => {
                  return (
                    <div className>
                      <Form.Item label={item.label} name={item.name}>
                        <div
                          style={{
                            height: "30px",
                            background: "#80808040",
                            border: "  1px solid #80808040  ",
                          }}
                          className=" text-left  p-1 rounded"
                        >
                          {" "}
                          {item.input}
                        </div>
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="  col-lg-5 col-md-6 col-sm-12 text-center ">
              <div className="form-heading">
                <p>Banking Information</p>{" "}
              </div>
              <div className="">
                {bankingInfo.map((item) => {
                  return (
                    <div className>
                      <Form.Item label={item.label} name={item.name}>
                        <div
                          style={{
                            background: "#80808040",
                            border: " #80808040 1px solid  ",
                          }}
                          className=" text-left p-1 rounded"
                        >
                          {" "}
                          <div>{item.input}</div>
                        </div>
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Form>
      )}
      <Form.Item className="d-flex justify-content-end">
        <Buttons
          disabled={uploadImage.isError ? true : false}
          className={"mt-2"}
          type={"primary"}
          title={title === "basic-form" ? "Next" : "Submit"}
          htmlType={"submit"}
        />
        {/* <Button type="primary" htmlType="submit">
              Submit
            </Button> */}
      </Form.Item>
    </Form>
  );
};

export default Forms;
