//Workforce (Client) Fields For Basic Form Comp
export const inputFields = [
  {
    name: "fName",
    label: "First Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "contactName1",
    label: "Contact 1 Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "lName",
    label: "Last Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "contactPhone1",
    label: "Contact 1 Phone",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "ssn",
    label: "SSN #",
    type: ["emp"],
  },
  {
    name: "ein",
    label: "EIN #",
    type: ["ind", "comp"],
  },
  {
    name: "contactName2",
    label: "Contact 2 Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "phone",
    label: "Phone",
    type: ["emp", "ind"],
  },
  {
    name: "phone",
    label: "Business Phone",
    type: ["comp"],
  },
  {
    name: "contactPhone2",
    label: "Contact 2 Phone",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "address",
    label: "Address",
    type: ["emp", "ind"],
  },
  {
    name: "address",
    label: "Business Address",
    type: ["comp"],
  },
  {
    isblank: true,
    type: ["ind", "comp"],
  },
  {
    name: "compName",
    label: "Company Name",
    type: ["comp"],
  },
  {
    isblank: true,
    type: ["comp"],
  },
  {
    name: "startDate",
    label: "Start Date",
    type: ["ind", "comp"],
  },
  {
    name: "make",
    label: "Make",
    type: ["ind", "comp"],
  },
  {
    name: "expireDate",
    label: "Expire Date",
    type: ["ind", "comp"],
  },
  {
    name: "model",
    label: "Model",
    type: ["ind", "comp"],
  },
  {
    name: "insuranceImage",
    label: "Insurance Picture",
    type: ["ind", "comp"],
  },
  {
    name: "year",
    label: "Year",
    type: ["ind", "comp"],
  },

  {
    name: "cglInsurance",
    label: "CGL Insurance",
    type: ["comp"],
  },
  {
    isblank: true,
    type: ["ind"],
  },
  {
    name: "vehicleImage",
    label: "Vehicle Picture",
    type: ["ind", "comp"],
  },
  {
    name: "cglStartDate",
    label: "Start Date",
    type: ["comp"],
  },
  {
    isblank: true,
    type: ["comp"],
  },
  {
    name: "cglExpireDate",
    label: "Expire Date",
    type: ["comp"],
  },
  {
    isblank: true,
    type: ["comp"],
  },
  {
    name: "cglinsuranceImage",
    label: "CGL Picture",
    type: ["comp"],
  },
  {
    name: "accountHolder",
    label: "Account Holder (Name)",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "bankName",
    label: "Bank Name",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "routing",
    label: "Routing #",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "account",
    label: "Account #",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmAccount",
    label: "Confirm Account #",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "profile",
    label: "Picture for Profile",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
];

export const inputFieldsForMobile = [
  {
    name: "compName",
    label: "Company Name",
    type: ["comp"],
  },
  {
    name: "fName",
    label: "First Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "lName",
    label: "Last Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "ssn",
    label: "SSN #",
    type: ["emp"],
  },
  {
    name: "ein",
    label: "EIN #",
    type: ["ind", "comp"],
  },
  {
    name: "phone",
    label: "Phone",
    type: ["emp", "ind"],
  },
  {
    name: "phone",
    label: "Business Phone",
    type: ["comp"],
  },
  {
    name: "address",
    label: "Address",
    type: ["emp", "ind"],
  },
  {
    name: "address",
    label: "Business Address",
    type: ["comp"],
  },
  {
    name: "contactName1",
    label: "Contact 1 Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "contactPhone1",
    label: "Contact 1 Phone",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "contactName2",
    label: "Contact 2 Name",
    type: ["emp", "ind", "comp"],
  },

  {
    name: "contactPhone2",
    label: "Contact 2 Phone",
    type: ["emp", "ind", "comp"],
  },

  {
    name: "make",
    label: "Make",
    type: ["ind", "comp"],
  },
  {
    name: "model",
    label: "Model",
    type: ["ind", "comp"],
  },
  {
    name: "year",
    label: "Year",
    type: ["ind", "comp"],
  },
  {
    name: "vehicleImage",
    label: "Vehicle Picture",
    type: ["ind", "comp"],
  },
  {
    name: "startDate",
    label: "Start Date",
    type: ["ind", "comp"],
  },

  {
    name: "expireDate",
    label: "Expire Date",
    type: ["ind", "comp"],
  },

  {
    name: "insuranceImage",
    label: "Insurance Picture",
    type: ["ind", "comp"],
  },


  {
    name: "cglInsurance",
    label: "CGL Insurance",
    type: ["comp"],
  },

  {
    name: "cglStartDate",
    label: "Start Date",
    type: ["comp"],
  },

  {
    name: "cglExpireDate",
    label: "Expire Date",
    type: ["comp"],
  },

  {
    name: "cglinsuranceImage",
    label: "CGL Picture",
    type: ["comp"],
  },
  {
    name: "accountHolder",
    label: "Account Holder (Name)",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "bankName",
    label: "Bank Name",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "routing",
    label: "Routing #",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "account",
    label: "Account #",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmAccount",
    label: "Confirm Account #",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
  {
    name: "profile",
    label: "Picture for Profile",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
];
//Workforce (Admin) Fields For Workforce Page
export const workforceInputFields = [
  {
    name: "userRole",
    label: "User Role",
    isSelect: true,
  },
  // {
  //   name: "contracterType",
  //   label: "Contracter Type",
  //   isSelect: true,
  // },

  // {
  //   name: "username",
  //   label: "User Name",
  // },
  {
    name: "email",
    label: "Email",
  },
  // {
  //   name: "phone",
  //   label: "Phone No",
  // },
];
export const selectWorkforce = [
  {
    option: "Employee",
  },
  {
    option: "Comapny/Contractor",
  },
  {
    option: "Individual/Contractor",
  },
];
// export const selectType = [
//   {
//     option: "Company",
//   },
//   {
//     option: "Individual",
//   },
// ];

//Admin fields for Admin Page
export const adminInputFields = [
  // {
  //   name: "username",
  //   label: "User Name",
  // },
  {
    name: "email",
    label: "Email",
  },
  // {
  //   name: "password",
  //   label: "Password",
  // },
  {
    name: "role",
    label: "Role",
  },
];

//tabs for workforce comp
export const empTabs = [
  {
    name: "Basic Form",
  },
  {
    name: "W4 Form",
  },
  {
    name: "Employee Handbook",
  },
];
export const contrTabs = [
  {
    name: "Basic Form",
  },
  {
    name: "W9 Form",
  },
  {
    name: "Sign the Contract",
  },
];
export const workforce = [
  {
    option: "employee",
  },
  {
    option: "contractor Company",
  },
  {
    option: "contractor Individual",
  },
];
