import React from "react";
import { Form } from "antd";

const ViewUserInfo = ({ particularUserData, rowId }) => {
  console.log("particularUserData ===>", particularUserData);

  const personalInfo = [
    {
      name: "fName",
      label: "First Name",

      input: particularUserData?.fName,
    },
    {
      name: "lName",
      label: "Last Name",
      input: particularUserData?.lName,
    },
    {
      name: "ssn",
      label: "SSN #",
      input: particularUserData?.ssn,
    },
    {
      name: "phone",
      label: "Phone",
      input: particularUserData?.phone,
    },
    {
      name: "address",
      label: "Address",
      input: particularUserData?.address,
    },
  ];

  const emergencyInfo = [
    {
      name: "contactName1",
      label: "Contact 1 Name",
      input: particularUserData?.contactName1,
    },
    {
      name: "contactPhone1",
      label: "Contact 1 Phone",
      input: particularUserData?.contactPhone1,
    },

    {
      name: "contactName2",
      label: "Contact 2 Name",
      input: particularUserData?.contactName2,
    },
    {
      name: "contactPhone2",
      label: "Contact 2 Phone",
      input: particularUserData?.contactPhone2,
    },
  ];

  const bankingInfo = [
    {
      name: "accountHolder",
      label: "Account Holder",
      input: particularUserData?.accountHolder,
    },
    {
      name: "bankName",
      label: "Bank Name",
      input: particularUserData?.bankName,
    },
    {
      name: "routing",
      label: "Routing #",
      input: particularUserData?.routing,
    },
    {
      name: "confirmRouting",
      label: "Confirm Routing #",
      input: particularUserData?.confirmRouting,
    },
    {
      name: "account",
      label: "Account #",
      input: particularUserData?.account,
    },
    {
      name: "confirmAccount",
      label: "Confirm Account #",
      input: particularUserData?.confirmAccount,
    },
    {
      name: "profile",
      label: "Picture for Profile",
      // input: particularUserData?.profile,
      input: (
        <img
          src={particularUserData?.profile}
          className="img-fluid"
          alt="No Img"
        />
      ),
    },
  ];

  return (
    <div className="mt-4">
      {particularUserData?.formStatus === 1 ? (
        <Form
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <div className="row justify-content-around">
            <div className="  col-lg-5 col-md-6 col-sm-12 text-center ">
              <h5 className="mb-4" style={{ color: "#1677FF" }}>
                Personal Information
              </h5>
              <div className="">
                {personalInfo.map((item) => {
                  return (
                    <div className>
                      <Form.Item label={item.label} name={item.name}>
                        <div
                          style={{
                            height: "35px",
                            background: "#80808040",
                            border: " #1677FF 1px solid  ",
                          }}
                          className=" text-left p-1 rounded"
                        >
                          {" "}
                          {item.input}
                        </div>
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="  col-lg-5 col-md-6 col-sm-12 text-center ">
              <h5 className="mb-4" style={{ color: "#1677FF" }}>
                Emergency Contact
              </h5>
              <div className="">
                {emergencyInfo.map((item) => {
                  return (
                    <div className>
                      <Form.Item label={item.label} name={item.name}>
                        <div
                          style={{
                            height: "35px",
                            background: "#80808040",
                            border: "  1px solid #1677FF  ",
                          }}
                          className=" text-left  p-1 rounded"
                        >
                          {" "}
                          {item.input}
                        </div>
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="  col-lg-5 col-md-6 col-sm-12 text-center ">
              <h5 className="mb-4 mt-4" style={{ color: "#1677FF" }}>
                Banking Information
              </h5>
              <div className="">
                {bankingInfo.map((item) => {
                  return (
                    <div className>
                      <Form.Item label={item.label} name={item.name}>
                        <div
                          style={{
                            background: "#80808040",
                            border: " #1677FF 1px solid  ",
                          }}
                          className=" text-left p-1 rounded"
                        >
                          {" "}
                          <div>{item.input}</div>
                        </div>
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>{" "}
        </Form>
      ) : (
        <div className="text-center mt-5">
          {" "}
          <h2 className="mt-5" style={{ color: "#4096FF" }}>
            This {particularUserData?.userRole} Form Not Filled Yet
          </h2>
        </div>
      )}
    </div>
  );
};

export default ViewUserInfo;
