import { Col, Divider, Row } from "antd";
import React, { useState } from "react";
import classes from "./Workforce.module.css";
import SignatureCanvas from "react-signature-canvas";
import page2 from "../../Asset/Images/W9FormPage2.png";
import page3 from "../../Asset/Images/W9FormPage3.png";
import page4 from "../../Asset/Images/W9FormPage4.png";
import page5 from "../../Asset/Images/W9FormPage5.png";
import page6 from "../../Asset/Images/W9FormPage6.png";

const inputFields = [
  {
    name: "name",
    title: "1",
    desc: "Name (as shown on your income tax return). Name is required on this line; do not leave this line blank.",
  },
  {
    name: "bussinessName",
    title: "2",
    desc: "Business name/disregarded entity name, if different from above",
  },
  {
    name: "address",
    title: "5",
    desc: "Address (number, street, and apt. or suite no.) See instructions.",
  },
  {
    name: "requesterName",
    title: "",
    desc: "Requester's name and address (optional)",
  },
  {
    name: "city",
    title: "6",
    desc: "City, state, and ZIP code",
  },
  {
    name: "accountNumber",
    title: "7",
    desc: "List account number(s) here (optional",
  },
];

const checkBox = [
  {
    name: "singleMember",
    desc: "Individual/sole proprietor or single-member LLC",
  },
  {
    name: "cCorporation",
    desc: "C Corporation",
  },
  {
    name: "sCorporation",
    desc: "S Corporation",
  },
  {
    name: "partnership",
    desc: "Partnership",
  },
  {
    name: "trustEstate",
    desc: "Trust/estate",
  },
  {
    name: "limitedLiabiblity",
    desc: "Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)►",
  },
  {
    name: "other",
    desc: "Other (see instructions)►",
  },
];

const numbersFieldsTitle = [
  "Social security number",
  "Employer identification number",
];
const numbersFields = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

const W9Form = ({basicFormData, canvasProps}) => {
  const [signatureRef, setSignatureRef] = useState(null);

  const handleClearSignature = () => {
    signatureRef.clear();
  };

  return (
    <div>
      <div className={classes.page}>
        <Row>
          <Col span={3} style={{ fontSize: "0.9vmin" }}>
            Form <span className={`fw-bolder ${classes.form}`}>W-9</span> <br />
            <br />
            <span style={{ fontSize: "1.1vmin" }}>
              (Rev. October 2018) Department of the Treasury Internal Revenue
              Service
            </span>
          </Col>

          <Col span={18} className={classes.colBorderLeft}>
            <span className="fw-bolder" style={{ fontSize: "2.7vmin" }}>
              <div className="d-flex justify-content-center ">
                {" "}
                Request for Taxpayer{" "}
              </div>
              <div
                className="d-flex justify-content-center "
                style={{ fontSize: "2.4vmin" }}
              >
                Identification Number and Certification
              </div>
            </span>
            <span
              className={`d-flex justify-content-center mb-1 ${classes.topSider}`}
              style={{ fontSize: "1.7vmin" }}
            >
              Go to www.irs.gov/FormW9 for instructions and the latest
              information.
            </span>
          </Col>

          <Col span={3} className={classes.colBorderLeft}>
            <div
              className={`fw-bold ${classes.topSider}`}
              style={{ fontSize: "1.4vmin" }}
            >
              Give Form to the requester. Do not send to the IRS.
            </div>
          </Col>
        </Row>
        <Divider className={classes.customDivider} />
        <Row>
          <Col span={1} style={{ fontSize: "2vmin" }}>
            <div className={classes.verticalText}>
              <div className="d-flex justify-content-center fw-bold">
                Print or type.
              </div>
              <div className="d-flex justify-content-center ">
                See Specific Instructions on page 3.
              </div>
            </div>
          </Col>
          <Col span={23} className={classes.colBorderLeft}>
            <Row>
              {inputFields.map((item) => (
                <Col
                  key={item.name}
                  span={
                    item.title === "5" || item.title === "6"
                      ? 16
                      : item.title === ""
                      ? 8
                      : 24
                  }
                  className={
                    item.title === "5" || item.title === "6"
                      ? classes.colBorderRight
                      : classes.colDiv
                  }
                >
                  {item.title === "7" ? (
                    <Divider className={classes.customDivider} />
                  ) : (
                    ""
                  )}
                  <div className="">
                    <span className="fw-bold">{item.title}</span> {item.desc}
                    <div className="">
                      {item.title === "" ? (
                        <>
                        <input className={classes.text} type={"text"} />
                        <input className={classes.text} type={"text"} />
                        </>
                      ) : (
                        <input
                          className={classes.text}
                          type={item.title === "7" ? "number" : "text"}
                          name={item.name}
                        />
                      )}
                    </div>
                  </div>
                  {item.title === "" ||
                  item.title === "6" ||
                  item.title === "7" ? (
                    ""
                  ) : (
                    <Divider className={classes.customDivider} />
                  )}

                  {item.title === "2" ? (
                    <>
                      <Row className={classes.checkboxColumn}>
                        <Col span={19} className={classes.colDiv}>
                          <div className=" ">
                            <span className="fw-bold">3</span> Check appropriate
                            box for federal tax classification of the person
                            whose name is entered on line 1. Check only one of
                            the following seven boxes.
                          </div>
                          <Row gutter={8} className="">
                            {checkBox.map((item) => (
                              <Col
                                key={item.name}
                                span={
                                  item.name === "limitedLiabiblity" ||
                                  item.name === "other"
                                    ? 24
                                    : item.name === "singleMember"
                                    ? 4
                                    : 5
                                }
                                className={`${classes.colDiv}`}
                              >
                                <input
                                  className={`me-1 ${classes.checkBox}`}
                                  type="checkbox"
                                  name={item.name}
                                />
                                {item.desc}
                                {item.name === "limitedLiabiblity" ? (
                                  <>
                                    <input
                                      className={`${classes.W9number}`}
                                      type="text"
                                      name="reportingCode"
                                    />{" "}
                                    <br />
                                    <div
                                      className="mx-2"
                                      style={{ fontSize: "1.8vmin" }}
                                    >
                                      <span className="fw-bold">Note: </span>
                                      Check the appropriate box in the line
                                      above for the tax classification of the
                                      single-member owner. Do not check LLC if
                                      the LLC is classified as a single-member
                                      LLC that is disregarded from the owner
                                      unless the owner of the LLC is another LLC
                                      that is not disregarded from the owner for
                                      U.S. federal tax purposes. Otherwise, a
                                      single-member LLC that is disregarded from
                                      the owner should check the appropriate box
                                      for the tax classification of its owner
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Col>
                            ))}
                          </Row>
                        </Col>
                        <Col span={5} className={classes.colBorderLeft}>
                          <div className=" ">
                            <span className="fw-bold">4 </span> Exemptions
                            (codes apply only to certain entities, not
                            individuals; see instructions on page 3):
                          </div>
                          <div className="">
                            Exempt payee code (if any)
                            <input
                              className={`${classes.W9number}`}
                              type="number"
                              name="payeeCode"
                            />
                          </div>
                          <div className="">
                            Exemption from FATCA reporting code (if any)
                            <input
                              className={`${classes.number}`}
                              type="number"
                              name="reportingCode"
                            />
                          </div>
                          <div className="\ " style={{ fontSize: "1.2vmin" }}>
                            (Applies to accounts maintained outside the U.S)
                          </div>
                        </Col>
                      </Row>
                      <Divider className={classes.customDivider} />
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Divider className={classes.customDivider} />
          <Col span={3}>
            <div className={classes.PartDiv}>Part I</div>
          </Col>
          <Col
            span={20}
            className={`fw-bolder ${classes.title}`}
            style={{ fontSize: "2.5vmin" }}
          >
            Taxpayer Identification Number (TIN)
          </Col>
          <Divider className={classes.customDivider} />
        </Row>
        <Row>
          <Col xs={24} md={12} lg={16} className={classes.colDiv}>
            <div>
              Enter your TIN in the appropriate box. The TIN provided must match
              the name given on line 1 to avoid backup withholding. For
              individuals, this is generally your social security number (SSN).
              However, for a resident alien, sole proprietor, or disregarded
              entity, see the instructions for Part I, later. For other
              entities, it is
            </div>
            <div className="mt-2">
              <span className="fw-bold"> Note:</span> If the account is in more
              than one name, see the instructions for line 1. Also see What Name
              and Number To Give the Requester for guidelines on whose number to
              enter.
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            {numbersFieldsTitle.map((item, index) => (
              <>
                <Row key={item}>
                  {index === 0 ? (
                    ""
                  ) : (
                    <Divider className={classes.customDivider} />
                  )}
                  <Col span={23} className={`fw-bold ${classes.colBorderLeft}`}>
                    {item}
                  </Col>
                  <Col span={1} className={classes.colBorderRight}></Col>
                  <Divider className={classes.customDivider} />
                </Row>
                {index === 0 ? (
                  <>
                    {numbersFields.map((number) => (
                      <React.Fragment key={number}>
                        <input
                          //   key={index + number}
                          className={
                            number === "2" || number === "7"
                              ? classes.boxFieldTopBtm
                              : number === "5" || number === "9"
                              ? classes.boxFieldTopRightBtm
                              : classes.boxFieldFull
                          }
                          //   type="number"
                          name={number}
                          maxlength="1"
                        />
                        {number === "3" || number === "5" ? (
                          <span className={`fw-bold ${classes.dash}`}>
                            &#8210;
                          </span>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ))}
                    <div className="fw-bold" style={{ fontSize: "2vmin" }}>
                      or
                    </div>
                  </>
                ) : (
                  numbersFields.map((number) => (
                    <React.Fragment key={number}>
                      <input
                        //   key={index + number}
                        className={
                          number === "2"
                            ? classes.boxFieldTopRightBtm
                            : number === "4" || number === "6" || number === "8"
                            ? classes.boxFieldTopBtm
                            : classes.boxFieldFull
                        }
                        // type="number"
                        name={number}
                        maxlength="1"
                      />
                      {number === "2" ? (
                        <span className={`fw-bold ${classes.dash}`}>
                          &#8210;
                        </span>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ))
                )}
              </>
            ))}
          </Col>
        </Row>
        <Row>
          <Divider className={classes.customDivider} />
          <Col span={3}>
            <div className={classes.PartDiv}>Part II</div>
          </Col>
          <Col
            span={20}
            className={`fw-bolder ${classes.title}`}
            style={{ fontSize: "2.5vmin" }}
          >
            Certification
          </Col>
          <Divider className={classes.customDivider} />
        </Row>

        <Row className={`mb-2 ${classes.colDiv}`}>
          <div>
            Under penalties of perjury, I certify that: <br /> 1. The number
            shown on this form is my correct taxpayer identification number (or
            I am waiting for a number to be issued to me); and <br /> 2. I am
            not subject to backup withholding because: (a) I am exempt from
            backup withholding, or (b) I have not been notified by the Internal
            Revenue Service (IRS) that I am subject to backup withholding as a
            result of a failure to report all interest or dividends, or (c) the
            IRS has notified me that I am no longer subject to backup
            withholding; and <br />
            3. I am a U.S. citizen or other U.S. person (defined below); and{" "}
            <br /> 4. The FATCA code(s) entered on this form (if any) indicating
            that I am exempt from FATCA reporting is correct.
          </div>
          <div>
            <span className="fw-bold">Certification instructions. </span>
            You must cross out item 2 above if you have been notified by the IRS
            that you are currently subject to backup withholding because you
            have failed to report all interest and dividends on your tax return.
            For real estate transactions, item 2 does not apply. For mortgage
            interest paid, acquisition or abandonment of secured property,
            cancellation of debt, contributions to an individual retirement
            arrangement (IRA), and generally, payments other than interest and
            dividends, you are not required to sign the certification, but you
            must provide your correct TIN. See the instructions for Part II,
            later.
          </div>
        </Row>

        <Row>
          <Divider className={classes.customDivider} />
          <Col span={2} style={{ fontSize: "2.4vmin" }}>
            <div className={"fw-bold"}>
              Sign <br /> Here
            </div>
          </Col>
          <Col span={14} className={classes.colBorderLeft}>
            <div className="px-1 fw-bolder ">
              Signature of U.S. person{" "}
              <button
                style={{ marginLeft: "6vmin" }}
                type="button"
                onClick={handleClearSignature}
              >
                Clear
              </button>
              <SignatureCanvas
                ref={(ref) => setSignatureRef(ref)}
                canvasProps={canvasProps}
              />
            </div>
          </Col>
          <Col span={8} className={classes.colBorderLeft}>
            <div className={`px-1 fw-bolder ${classes.dateDiv}`}>
              Date ►
              <input className={classes.text} type={"date"} name={"signDate"} />
            </div>
          </Col>
          <Divider className={classes.customDivider} />
        </Row>

        <Row justify={"space-between"}>
          <Col span={12} className={classes.colDiv}>
            <div style={{ fontSize: "3vmin", fontWeight: "bold", margin: 2 }}>
              General Instructions
            </div>
            <div className="mb-1">
              Section references are to the Internal Revenue Code unless
              otherwise noted.
            </div>
            <div>
              <span className="fw-bold">Future developments.</span> For the
              latest information about developments related to Form W-9 and its
              instructions, such as legislation enacted after they were
              published, go to www.irs.gov/FormW9.
            </div>
            <div style={{ fontSize: "3vmin", fontWeight: "bold", margin: 2 }}>
              Purpose of Form
            </div>
            <div>
              An individual or entity (Form W-9 requester) who is required to
              file an information return with the IRS must obtain your correct
              taxpayer identification number (TIN) which may be your social
              security number (SSN), individual taxpayer identification number
              (ITIN), adoption taxpayer identification number (ATIN), or
              employer identification number (EIN), to report on an information
              return the amount paid to you, or other amount reportable on an
              information return. Examples of information returns include, but
              are not limited to, the following. <br />
              <span className="fw-bold">• </span> Form 1099-INT (interest earned
              or paid)
            </div>
          </Col>
          <Col span={10} className={`mt-2 ${classes.colDiv}`}>
            <span className="fw-bold">•</span> Form 1099-DIV (dividends,
            including those from stocks or mutual funds) <br />
            <span className="fw-bold">•</span> Form 1099-MISC (various types of
            income, prizes, awards, or gross proceeds)
            <br />
            <span className="fw-bold">•</span> Form 1099-B (stock or mutual fund
            sales and certain other transactions by brokers)
            <br />
            <span className="fw-bold">•</span> Form 1099-S (proceeds from real
            estate transactions)
            <br />
            <span className="fw-bold">•</span> Form 1099-K (merchant card and
            third party network transactions)
            <br />
            <span className="fw-bold">•</span> Form 1098 (home mortgage
            interest), 1098-E (student loan interest), 1098-T (tuition)
            <br />
            <span className="fw-bold">•</span> Form 1099-C (canceled debt)
            <br />
            <span className="fw-bold">•</span> Form 1099-A (acquisition or
            abandonment of secured property)
            <br />
            Use Form W-9 only if you are a U.S. person (including a resident
            alien), to provide your correct TIN. If you do not return Form W-9
            to the requester with a TIN, you might be subject to backup
            withholding. See What is backup withholding, later.
          </Col>
        </Row>

        <Row className="mt-1">
          <Divider className={classes.customDivider} />
          <Col
            span={16}
            className={`d-flex justify-content-center ${classes.colDiv}`}
          >
            Cat. No. 10231X
          </Col>
          <Col
            span={8}
            className={`d-flex justify-content-end ${classes.colDiv}`}
          >
            Form &nbsp; <span className="fw-bold"> W-9 </span>&nbsp; (Rev.
            10-2018)
          </Col>
        </Row>
      </div>

      {/* Page 2 */}
      <div className={classes.page2}>
        <img
          src={page2}
          alt="Image1"
          className={`pe-1 ${classes.page2Image}`}
        />
      </div>

      {/* Page 3 */}
      <div className={classes.page2}>
        <img
          src={page3}
          alt="Image2"
          className={`pe-1 ${classes.page2Image}`}
        />
      </div>
      {/* Page 4 */}
      <div className={classes.page2}>
        <img
          src={page4}
          alt="Image3"
          className={`pe-1 ${classes.page2Image}`}
        />
      </div>
      {/* Page 5 */}
      <div className={classes.page2}>
        <img
          src={page5}
          alt="Image4"
          className={`pe-1 ${classes.page2Image}`}
        />
      </div>
      {/* Page 6 */}
      <div className={classes.page2}>
        <img
          src={page6}
          alt="Image5"
          className={`pe-1 ${classes.page2Image}`}
        />
      </div>
    </div>
  );
};

export default W9Form;
