import React, { useEffect, useState } from "react";
import BasicForm from "../../Components/Employee/BasicForm";
import W4Form from "../../Components/Employee/W4Form";
import Handbook from "../../Components/Employee/Handbook";
import { Form, Select } from "antd";
import { contrTabs, empTabs, workforce } from "../../Utils/dataUtils";
import { userUpdataData } from "../../Store/ApiActions/userDataApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Person3Icon from "@mui/icons-material/Person3";
import Cards from "../../Components/Cards/Cards";
import { getWorkforce } from "../../Store/ApiActions/workforceApi";

const cardsInfo = [
  {
    title: "Employees",
    icon: <Diversity1Icon />,
    url: "/employee",
    access: ["Employee", "Admin", "SuperAdmin"],
  },
  {
    title: "Individual",
    icon: <Person3Icon />,
    url: "/individual",
    access: ["Admin", "SuperAdmin"],
  },
  {
    title: "Company",
    icon: <ApartmentIcon />,
    url: "/company",
    access: ["Admin", "SuperAdmin"],
  },
];

const WorkforcePortal = ({ path, isAdmin, iscollapsed }) => {
  const allWorkforceData = useSelector(
    (state) => state.workforce?.getworkforceData
  );
  console.log(allWorkforceData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWorkforce());
  }, [dispatch]);

  const [selectedTab, setSelectedTab] = useState("Basic Form");
  const [basicFormData, setBasicFormData] = useState({});
  const [workforceData, setWorkforceData] = useState("");
  const [formData, setFormData] = useState({ profile: {}, allData: {} });

  console.log("workforce?>>>", workforceData);
  console.log("path", path);

  // Check if there is saved form data when the component mounts
  useEffect(() => {
    const savedData = localStorage.getItem("BasicForm");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData(parsedData);
    }
  }, []);

  useEffect(() => {
    setWorkforceData(path);
  }, [path]);

  const handleClick = (e) => {
    if (selectedTab === "Employee Handbook") {
      setSelectedTab(e.target.value);
    } else if (e.target.value === "Basic Form") {
      setSelectedTab("Basic Form");
    }
    // setSelectedTab(e.target.value);
  };

  const form = new FormData();

  const handleFormNext = async (values, image) => {
    if (
      values.routing === values.confirmRouting &&
      values.account === values.confirmAccount
    ) {
      Object.entries(values).forEach(([name, value]) => {
        if (name !== "profile") {
          form.append(name, value === undefined ? "" : value);
        }
      });
      form.append("profile", image);
      form.append("formStatus", 1);
      // form.append("userStatus", 0);
      // form.append("activeStatus", 0);

      for (var pair of form.entries()) {
        console.log(pair[0], "", pair[1]);
      }
      console.log(image);
      // const response = await dispatch(userUpdataData(form));
      // console.log(response);
      // if (response.payload.status) {
      //   setTimeout(() => {
      //     toast.success("Added Succesfuly");
      //   }, 2000);
      // }
      // const fileds = {
      //   ...values,
      //   profile:image
      // }
      // console.log("image data",fileds);

      localStorage.setItem("BasicForm", JSON.stringify(values));
      const fields = {
        fName: values["fName"],
        lName: values["lName"],
        ssn: values["ssn"],
        address: values["address"],
      };
      setBasicFormData(fields);
      setFormData({
        ...formData,
        profile: image,
        allData: values,
      });
      setSelectedTab("W4 Form");
    } else if (values.routing !== values.confirmRouting) {
      toast.warning("Please match Routing information");
    } else if (values.account !== values.confirmAccount) {
      toast.warning("Please match Accounts information");
    }
  };

  return (
    <div>
      {workforceData === "/form" ? (
        <div className="home-cards w-100 py-2 text-center">
          <div className="d-flex flex-wrap flex-grow-2 justify-content-around mt-4">
            {cardsInfo.map((item) => (
              // <Cards title={item.title} icon={item.icon} url={item.url} />
              <Cards
                title={item.title}
                icon={item.icon}
                url={item.url}
                access={item.access}
                isAdmin={isAdmin}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="blur-div"></div>
          <div className=" justify-content-center pt-3">
            <div className="blur-div-top-bar"></div>
            <div
              className="nav-bar"
              // style={iscollapsed ? { width: "55vw" } : {}}
            >
              {(workforceData === "/employee" ? empTabs : contrTabs)?.map(
                (item) => {
                  // console.log(item);
                  return (
                    <div key={item.name}>
                      <div
                        className={`${
                          selectedTab === item.name ? "borders-bottom" : ""
                        }`}
                      >
                        <button
                          style={
                            selectedTab === "Basic Form" &&
                            (item.name === "W4 Form" ||
                              item.name === "Employee Handbook")
                              ? { cursor: "not-allowed" }
                              : selectedTab === "W4 Form" &&
                                item.name === "Employee Handbook"
                              ? { cursor: "not-allowed" }
                              : {}
                          }
                          value={item.name}
                          onClick={handleClick}
                          className={` ${
                            selectedTab === item.name
                              ? "border-0 nav-bar-btn tab-text-color"
                              : "border-0 nav-bar-btn"
                          }`}
                        >
                          {item.name}
                        </button>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div className="border-0 mt-3 pt-4">
              {selectedTab === "Basic Form" ? (
                <BasicForm
                  onFinish={handleFormNext}
                  path={workforceData}
                  formData={formData}
                  allWorkforceData={allWorkforceData}
                />
              ) : selectedTab === "W4 Form" || selectedTab === "W9 Form" ? (
                <W4Form
                  basicFormData={basicFormData}
                  path={workforceData}
                  allBasicformData={formData}
                  allWorkforceData={allWorkforceData}
                />
              ) : selectedTab === "Employee Handbook" ? (
                <Handbook
                  path={workforceData}
                  allWorkforceData={allWorkforceData}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WorkforcePortal;
